<template>
  <div class="page-wrapper">
    <div class="invoice-content" v-if="$route.query.from!='eit'">
      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />
      <div class="invoice-text">{{$route.query.from=='lvat'?'数据采集中':'数据授权完成'}} <span type="link" v-if="$route.query.from=='lvat'" @click="retry" style="color:#165dff;cursor: pointer;">重新授权</span></div>
      <div class="invoice-text">在此期间，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。</div>
      <!-- <div class="invoice-text">数据采集需T+1个工作日完成，请耐心等待</div> -->
    </div>
    <div class="invoice-content eitBox" v-else >
      <img class="invoice-img eitImg" src="@/assets/invoiceUploadV2SuccessFromEit.png" alt />
      <div class="invoice-text bold">数据采集中， <span type="link"  @click="retry" style="color:#165dff;cursor: pointer;">重新授权</span></div>
      <div class="invoice-text">• 在此期间，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。</div>
      <div class="invoice-text">• 采集成功后，可前往 <span style="color:#165dff;cursor: pointer;" @click="toFx">【风险检测】</span>菜单中查看检测报告。</div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
// import { zq2name } from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";

localize("zh_CN", zh_CN);

export default {
  components: {
    BreadCrumb,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqBq: [String, Number],
    uuid: String,
    zq: String,
  },
  data: function () {
    return {
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      taxPayerID: "",
      mobile: "",
      userInfo: sessionStorage.getItem("method") || 1,
    };
  },
  // computed: {
  //   ...mapState(['companyInfo', 'authorization']),
  //   ...mapState('UploadTable', ['permissionID']),
  //   ...mapState(['customization']),
  //   zqName: function () {
  //     return zq2name(this.zq);
  //   },
  //   isOfficial() {
  //     return localStorage.getItem('IsOfficial');
  //   },
  // },
  // mounted() {
  //   sessionStorage.removeItem('isShowBase');
  //   history.pushState(null, null, document.URL);
  //   window.addEventListener('popstate', function () {
  //     history.pushState(null, null, document.URL);
  //   });
  //   this.isLoading = true;

  //   setTimeout(() => {
  //     this.isLoading = false;
  //   }, 500);
  // },
  methods: {
    retry(){
      this.loading = this.$loading({
        lock: true,
        text: '加载中···',
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      window.parent && window.parent.postMessage("v3-retry", "*");

    },
    toFx(){
      window.parent && window.parent.postMessage("v3-toFX", "*");
    },
    handleBtnNext2() {
      this.$refs["validationObserver"].validate().then((success) => {
        if (!success) {
          return;
        } else {
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      });
    },

    toTest() {
      this.$router.push({ name: "result" });
    },
    // genTestReport({
    //       bankId: this.bankId,
    //       goodsId: this.productID,
    //       kjzd: this.companyInfo.kjzd,
    //       token: this.authorization,
    //       nsrsbh: this.companyInfo.nsrsbm,
    //       tag: this.version,
    //       id: this.$store.state.UploadTable.permissionID,
    //       uuid: this.uuid,
    //       zq,
    //       invoice_nsrsbh: this.inVoiceTaxPayerID,
    //       invoice_email: this.inVoiceEmail,
    //       invoice_company_name: this.inVoiceCompanyName,
    //       invoice_phone: this.inVoiceMobile
    //     });
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
}

.form-wrapper {
  display: flex;
  margin-top: 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}
.eitBox.invoice-content{
  .invoice-text{
    color: #606368;
    font-weight: 400;
    font-size: 14px;
    margin-top :0px;
  }
  .invoice-text+.invoice-text{
    margin-bottom :4px
  }
  .bold.invoice-text{
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-top :30px;
    margin-bottom :20px;

  }
}
.invoice-content {
  text-align: center;
  height: 100vh;
  display flex
  justify-content center
  align-items center
  flex-direction: column;
  .eitImg{
    width:120px;
    height:120px;
  }
}

.invoice-img {
  max-width 80%
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.toTestLog {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.toTestLog:hover {
  background: rgba(47, 54, 235, 1);
}

.ease {
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .invoice-text{
    font-size 14px

  }
}
</style>
